"use client";
import React, { useState, useEffect, useRef, useContext, use } from "react";
import Link from "next/link";

import BurgerMenu from "@icons/general/burger-menu";
import AuthModal from "../../modals/AuthModal";
import { toast } from "react-toastify";
import GenericModal from "../../modals/GenericModal";
import { AnimatePresence } from "framer-motion";
import { Context } from "@context/store";
import { usePathname } from "next/navigation";
import Image from "next/image";
const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [
    {
      authModalOpened: isModalVisible,
      formType,
      user: loggedUser,
      notifications,
    },
    dispatch,
  ] = useContext(Context);

  const dropdownRef = useRef(null);
  const walletRef = useRef(null);

  const isLoggedIn = loggedUser;

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
    if (walletRef.current && !walletRef.current.contains(event.target)) {
      setShowWallet(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible || showWallet) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisible, showWallet]);

  const handleLoginClick = () => {
    setDropdownVisible(false);
    dispatch({ type: "formType", payload: "login" });
    dispatch({ type: "authModalOpened", payload: true });
  };

  const handleSignupClick = () => {
    setDropdownVisible(false);
    dispatch({ type: "formType", payload: "register" });
    dispatch({ type: "authModalOpened", payload: true });
  };
  const handleLogoutClick = () => {
    dispatch({ type: "user", payload: null });
    dispatch({ type: "token", payload: null });
    localStorage.removeItem("LIH-Token");
    toast.warning("You've been logged out");
  };

  const [scrolled, setScrolled] = useState(false);
  const isBaseRoute = usePathname() === "/";
  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  }, []);

  const [currentLogo, setCurrentLogo] = useState(
    scrolled ? "/assets/imgs/logo-small.svg" : "/assets/imgs/logo-dark.png"
  );
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setIsTransitioning(true);
    const timer = setTimeout(() => {
      setCurrentLogo(
        scrolled ? "/assets/imgs/logo-small.svg" : "/assets/imgs/logo-dark.png"
      );
      setIsTransitioning(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [scrolled]);

  return (
    <div
      className={
        scrolled
          ? `navbar-container fixed base-route-scrolled sub-base-route-scrolled`
          : `navbar-container ${isBaseRoute ? "" : "sub-base-route"}`
      }
    >
      <nav className="navbar">
        <div
          className={`container ${
            usePathname() === "/" || usePathname() === "/AllProperties"
              ? "big"
              : ""
          }`}
        >
          <div className="navbar-container">
            <Link prefetch={true} href={"/"}>
              <div className="navbar-logo">
                {/* <Image
                  height={35}
                  width={100}
                  src="/assets/imgs/logo.png"
                  className="logo"
                  alt="Logo"
                />
                <Image
                  height={35}
                  width={100}
                  src={currentLogo}
                  className={`logo-dark ${isTransitioning ? "fade" : ""}`}
                  alt="Logo-dark"
                /> */}
                <svg
                  width="105"
                  height="35"
                  viewBox="0 0 105 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="logo"
                  style={
                    scrolled
                      ? {
                          translate: "-40px 0",
                        }
                      : null
                  }
                >
                  <rect x="51.7578" width="8.45203" height="26.5003" />
                  <path d="M95.6888 0.000198364V18.5896C95.0616 15.8783 94.0873 13.4268 92.7699 11.2566C90.7657 7.95446 87.972 5.29661 84.4669 3.3572C78.5167 0.0648057 72.416 0.000198364 72.416 0.000198364H64.2812V26.5003H72.7333V0.846455C74.0338 0.911062 79.0685 1.33608 84.0579 4.0967C87.4325 5.96395 90.1204 8.52042 92.0475 11.6951C94.4612 15.6722 95.6862 20.6439 95.6888 26.4725V26.5003H104.141V0.000198364H95.6888Z" />
                  <path
                    d="M0 0.000137329H1.77939V24.9859H8.74505V26.5003H0V0.000137329Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <rect
                    x="14.1211"
                    y="0.000198364"
                    width="1.77938"
                    height="26.5001"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <path
                    d="M21.5029 0.000137329H23.3581L27.9386 25.1374H28.1658L32.7086 0.000137329H34.5638L29.5665 26.5003H26.5379L21.5029 0.000137329Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <path
                    d="M40.2046 0.000137329H48.3439V1.51453H41.984V11.7738H47.9654V13.288H41.984V24.9859H48.3439V26.5003H40.2046V0.000137329Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <path
                    d="M30.0986 29.3961H30.6628V34.5252H30.0986V29.3961ZM30.5749 31.5356H33.2054V32.0045H30.5749V31.5356ZM33.132 29.3961H33.689V34.5252H33.132V29.3961Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <path
                    d="M39.4079 34.3236C39.117 34.1403 38.8996 33.8546 38.7556 33.4664C38.6115 33.078 38.5396 32.5736 38.5396 31.9532C38.5396 31.3377 38.6115 30.837 38.7556 30.4511C38.8996 30.0653 39.117 29.7806 39.4079 29.5975C39.6983 29.4142 40.0661 29.3228 40.5104 29.3228C40.9501 29.3228 41.3165 29.4142 41.6095 29.5975C41.9027 29.7806 42.1224 30.0653 42.2691 30.4511C42.4156 30.837 42.4887 31.3377 42.4887 31.9532C42.4887 32.5736 42.4156 33.078 42.2691 33.4664C42.1224 33.8546 41.9027 34.1403 41.6095 34.3236C41.3165 34.5067 40.9501 34.5983 40.5104 34.5983C40.0661 34.5983 39.6983 34.5067 39.4079 34.3236ZM41.3091 33.9169C41.5143 33.7656 41.6646 33.5286 41.7598 33.2062C41.855 32.8837 41.9027 32.4661 41.9027 31.9532C41.9027 31.4501 41.855 31.0372 41.7598 30.7147C41.6646 30.3925 41.5143 30.1555 41.3091 30.004C41.104 29.8528 40.8378 29.777 40.5104 29.777C40.1832 29.777 39.9182 29.8528 39.7154 30.004C39.5128 30.1555 39.3625 30.3925 39.2649 30.7147C39.1671 31.0372 39.1184 31.4501 39.1184 31.9532C39.1184 32.4661 39.1671 32.8837 39.2649 33.2062C39.3625 33.5286 39.5128 33.7656 39.7154 33.9169C39.9182 34.0683 40.1832 34.1439 40.5104 34.1439C40.8378 34.1439 41.104 34.0683 41.3091 33.9169Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <path
                    d="M48.1234 29.865H46.812V29.3961H49.9993V29.865H48.695V34.5251H48.1234V29.865Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <path
                    d="M54.6299 29.3961H57.2969V29.8722H55.1941V31.6089H57.1798V32.0704H55.1941V34.0488H57.2969V34.5251H54.6299V29.3961Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <path
                    d="M62.0742 29.3961H62.6384V34.0635H64.6681V34.5251H62.0742V29.3961Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                  <path
                    d="M69.816 34.4409C69.5693 34.3262 69.3715 34.1343 69.2224 33.8658C69.0735 33.5972 68.9892 33.2406 68.9697 32.7959H69.5192C69.5387 33.1183 69.5961 33.3795 69.6916 33.5799C69.7866 33.7803 69.9198 33.9292 70.0907 34.027C70.2617 34.1248 70.4742 34.1733 70.7283 34.1733C71.0847 34.1733 71.3535 34.0904 71.5344 33.9244C71.7148 33.7584 71.8053 33.5408 71.8053 33.2722C71.8053 33.0475 71.7504 32.8669 71.6405 32.7299C71.5306 32.5933 71.3962 32.482 71.2374 32.3965C71.0787 32.3112 70.8624 32.222 70.5891 32.1291C70.2617 32.0218 69.9991 31.9166 69.8013 31.814C69.6035 31.7115 69.4349 31.565 69.2958 31.3743C69.1566 31.1839 69.087 30.9324 69.087 30.6197C69.087 30.3511 69.1431 30.1191 69.2554 29.9235C69.3679 29.7283 69.5413 29.5794 69.7756 29.4769C70.0102 29.3741 70.3056 29.3228 70.6623 29.3228C70.9994 29.3228 71.2839 29.3755 71.5159 29.4804C71.7479 29.5856 71.9309 29.7675 72.0655 30.0263C72.1997 30.2851 72.2766 30.6394 72.2963 31.0887H71.7469C71.7272 30.7662 71.6795 30.5098 71.6037 30.3193C71.528 30.1289 71.4157 29.9895 71.2668 29.9017C71.1177 29.8138 70.9139 29.7699 70.6549 29.7699C70.4205 29.7699 70.2287 29.8041 70.0798 29.8723C69.9307 29.9408 69.8221 30.0384 69.7538 30.1653C69.6852 30.2925 69.6512 30.4319 69.6512 30.5831C69.6512 30.7835 69.7035 30.9495 69.8087 31.0813C69.9136 31.2131 70.0442 31.3183 70.2007 31.3964C70.3569 31.4745 70.567 31.565 70.8308 31.6675C71.168 31.7848 71.4377 31.8936 71.6405 31.9936C71.8431 32.0938 72.0166 32.2452 72.1607 32.448C72.3049 32.6508 72.3768 32.913 72.3768 33.2356C72.3768 33.5238 72.3182 33.7694 72.2011 33.9719C72.0838 34.1747 71.9005 34.3322 71.6514 34.4447C71.4023 34.557 71.0897 34.613 70.7137 34.613C70.3619 34.613 70.0627 34.5556 69.816 34.4409Z"
                    style={scrolled ? { opacity: 0 } : null}
                  />
                </svg>
              </div>
            </Link>
            <div className="navbar-links">
              {/* <Link to="/">Explore</Link> */}

              {loggedUser && loggedUser.role == "Host" ? (
                <a className="" href="https://app.dev-server.one/dashboard">
                  Go to Dashboard
                </a>
              ) : (
                <Link href="/ListYourProperty">List Your Property</Link>
              )}
              {/* <Link to="/join-us">Join Us</Link> */}
            </div>
            <div className="navbar-right">
              {/* {isLoggedIn && (
                <div className="wallet-button" ref={walletRef}>
                  <button
                    className="nav-btn wallet-button lg "
                    onClick={() => setShowWallet((prev) => !prev)}
                  >
                    <FontAwesomeIcon icon={faWallet} /> Wallet
                  </button>
                  {showWallet && <WalletModal />}
                </div>
              )} */}

              {/* <Link
                className="nav-btn dark lg nl get-the-app-button"
                href={"/get-app"}
              >
                <FontAwesomeIcon icon={faDownload} /> Get the app
              </Link> */}
              {/* <button
                id="propertyTypeButton"
                className="nav-btn hover"
                onClick={() => setShowPropertyTypeSelector(true)}
              >
                {currentType === "hotel" ? <HotelIcon /> : <ApartmentIcon />}
              </button>
              <AnimatePresence>
                {showPropertyTypeSelect && (
                  <GenericModal
                    type="property"
                    onClose={setShowPropertyTypeSelector}
                  >
                    <>
                      <h4 className="title">What are you looking for?</h4>
                      <div className="property-types">
                        <button
                          className={`type ${
                            selectedType === "hotel" ? "selected" : ""
                          }`}
                          onClick={() => setSelectedType("hotel")}
                        >
                          {selectedType === "hotel" ? (
                            <CheckMark />
                          ) : (
                            <HotelIcon />
                          )}
                          Hotel
                        </button>
                        <button
                          className={`type ${
                            selectedType === "apartment" ? "selected" : ""
                          }`}
                          onClick={() => setSelectedType("apartment")}
                        >
                          {selectedType === "apartment" ? (
                            <CheckMark />
                          ) : (
                            <ApartmentIcon />
                          )}
                          Apartment
                        </button>
                      </div>
                      <div className="button-container">
                        <button
                          className="button-link"
                          onClick={() => setShowPropertyTypeSelector(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="submit-button"
                          onClick={handleChangeType}
                        >
                          Done
                        </button>
                      </div>
                    </>
                  </GenericModal>
                )}
              </AnimatePresence> */}
              <div
                className="nav-btn nogap"
                ref={dropdownRef}
                onClick={toggleDropdown}
              >
                <BurgerMenu />
                <div className="nav-btn dark user-icon">
                  {/* <FontAwesomeIcon icon={faUser} /> */}
                  <Image
                    src={"/assets/icons/svgs/user-icon.svg"}
                    width={24}
                    height={24}
                    alt="user"
                  />
                  {isLoggedIn &&
                  notifications.filter((notification) => notification.new)
                    ?.length > 0 ? (
                    <div className="notifications-indicator">
                      {
                        notifications.filter((notification) => notification.new)
                          ?.length
                      }
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {dropdownVisible && (
                  <div className="user-dropdown">
                    {isLoggedIn ? (
                      <>
                        {loggedUser && loggedUser?.role == "Influencer" && (
                          <Link href="/Account/pages/Profile">Profile</Link>
                        )}
                        {loggedUser && loggedUser.role == "Host" ? (
                          <a
                            className="navbar-link-mobile"
                            href="https://app.dev-server.one/dashboard"
                          >
                            Go to Dashboard
                          </a>
                        ) : (
                          <Link
                            className="navbar-link-mobile"
                            href="/ListYourProperty"
                          >
                            List Your Property
                          </Link>
                        )}
                        <div className="notification-link">
                          <Link href="/Notifications">
                            Notifications
                            {notifications.filter(
                              (notification) => notification.new === true
                            )?.length != 0 ? (
                              <span className="notification-dot"></span>
                            ) : null}
                          </Link>
                        </div>
                        {isLoggedIn ? <Link href="/Chat">Chat</Link> : null}

                        {/* <Link to="/Transactions">Transactions</Link> */}
                        {/* <a href="#">Accommodation</a> */}
                        {/* <Link to="/wishList">Wishlist</Link> */}
                        <hr />
                        <Link href="/Account">Account</Link>
                        {/* <Link to="/Account/HelpCenter">Help center</Link> */}
                        <hr />
                        <a onClick={handleLogoutClick}>Log out</a>
                      </>
                    ) : (
                      <>
                        {/* <Link href={"/"} className="navbar-link-mobile">
                          Explore
                        </Link> */}
                        {loggedUser && loggedUser.role == "Host" ? (
                          <a
                            className="navbar-link-mobile"
                            href="https://app.dev-server.one/dashboard"
                          >
                            Go to Dashboard
                          </a>
                        ) : (
                          <Link
                            className="navbar-link-mobile"
                            href="/ListYourProperty"
                          >
                            List Your Property
                          </Link>
                        )}
                        {/* <Link className="navbar-link-mobile" href="/join-us">
                          Join Us
                        </Link> */}
                        <a onClick={handleSignupClick}>Sign up</a>
                        <a onClick={handleLoginClick}>Login</a>
                        <hr />
                        {/* <Link to="/Account/HelpCenter">Help center</Link> */}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <AnimatePresence>
        {isModalVisible && (
          <GenericModal
            onClose={() =>
              dispatch({ type: "authModalOpened", payload: false })
            }
          >
            <AuthModal
              formType={formType}
              onClose={() =>
                dispatch({ type: "authModalOpened", payload: false })
              }
            />
          </GenericModal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;
