"use client";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const InputPassword = ({
  label,
  value,
  placeholder,
  onChange,
  required = false,
  maxLength,
}) => {
  const [show, setShow] = useState(false);

  const handleToggleShow = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow((prev) => !prev);
  };

  return (
    <div className="input-container">
      {label && <label>{label}</label>}
      <div className="password-container">
        <input
          type={show ? "text" : "password"}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          maxLength={maxLength}
        />
        <button className="show-password" onClick={handleToggleShow}>
          <FontAwesomeIcon icon={show ? faEye : faEyeSlash} />
        </button>
      </div>
    </div>
  );
};
