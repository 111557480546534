"use client";
import axios from "axios";
import { useRouter } from "next/navigation";
import { useContext } from "react";
import { Context } from "../context/store";

const BASE_URL =
  process.env.NEXT_PUBLIC_SERVER_BASE_URL || "http://api.dev-server.one/";

export const useAxiosPrivate = () => {
  const [state, dispatch] = useContext(Context);
  const router = useRouter();
  const axiosPrivate = axios.create({
    baseURL: BASE_URL,
  });

  axiosPrivate.interceptors.request.use(
    (requestConfig) => {
      if (localStorage.getItem("LIH-Token")) {
        requestConfig.headers.Authorization = `Bearer ${localStorage.getItem(
          "LIH-Token"
        )}`;
        requestConfig.withCredentials = true;
      }
      return requestConfig;
    },
    (error) => Promise.reject(error)
  );

  axiosPrivate.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const status = error.response.status;
        const expectedError = status >= 400 && status < 500;

        if (!expectedError && status === 401) {
          dispatch({ type: "LOGOUT" });
          router.push("/login");
        }
      } else {
        console.error("Network or CORS error:", error);
      }

      return Promise.reject(error);
    }
  );

  return axiosPrivate;
};
