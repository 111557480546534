import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/LIH-Frontend-Guest/components/general/Nav/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/LIH-Frontend-Guest/components/ToastProviderCustom/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Store"] */ "/var/www/html/LIH-Frontend-Guest/context/store.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/html/LIH-Frontend-Guest/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/style/base.scss");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/next/font/google/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-poppins\",\"weight\":[\"400\",\"500\",\"700\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/next/font/local/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/assets/fonts/scale-vf.ttf\",\"variable\":\"--font-ScaleVF\",\"display\":\"swap\",\"weight\":\"normal\",\"style\":\"normal\"}],\"variableName\":\"ScaleVF\"}");
