"use client";
import dayjs from "dayjs";
import React, {
  createContext,
  useReducer,
  useEffect,
  useCallback,
} from "react";
import { useAxiosPrivate } from "../api/axios";
const d = dayjs();
import { socket } from "@/app/socket";
const initialState = {
  user: null,
  loader: false,
  selectedType: "",
  bookingInfo: {
    start_date: d.toString(),
    end_date: d.add(1, "month").toString(),
    roomCount: 1,
    amenities: ["all"],
    guests: {
      adults: 1,
      children: 0,
      infants: 0,
      pets: 0,
    },
    location: {
      location: "all",
      city: "",
    },
  },
  notifications: [],
  prevBookingInfo: {},
  currentProperty: {},
  allProperties: [],
  month_count: 1,
  allPropertyAmenities: [],
  recentlyViewedProperties: [],
  allPropertiesAvailability: [],
  authModalOpened: false,
  formType: "login",
  propertyAvailability: {},
  wishList: [],
  pagination: {
    totalPages: 0,
    totalCount: 0,
  },
};

const reducer = (state, action) => ({
  ...state,
  [action.type]: action.payload,
});

export const Context = createContext([initialState, () => null]);

export const Store = ({ children }) => {
  const axios = useAxiosPrivate();
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    const storedState =
      typeof window !== "undefined" && localStorage.getItem("mainState")
        ? JSON.parse(localStorage.getItem("mainState"))
        : initialState;
    if (dayjs(storedState.bookingInfo.start_date).isBefore(dayjs())) {
      storedState.bookingInfo.start_date = dayjs().toString();
      storedState.bookingInfo.end_date = dayjs().add(1, "month").toString();
    }
    return {
      ...storedState,
      loader: false,
      loadingRooms: false,
      user: null,
      allProperties: [],
      allPropertiesAvailability: [],
      allPropertyAmenities: [],
      selectedType: "",
      authModalOpened: false,
      formType: "login",
      notifications: [],
      propertyAvailability: {},
      wishList: [],
      prevBookingInfo: {},
      pagination: {
        totalPages: 0,
        totalCount: 0,
      },
    };
  });

  const addToNotifications = useCallback(
    (data) => {
      dispatch({
        type: "notifications",
        payload: Array.isArray(state.notifications) 
          ? [data, ...state.notifications]
          : [data]
      });
    },
    [state.notifications, dispatch]
  );

  useEffect(() => {
    if (socket.connected) {
      onConnect();
    }

    function onConnect() {
      if (!state.user) return;
      socket.emit("setup", { _id: state.user._id });
      socket.on("receiveNotification", (data) => {
        console.log(data);
        addToNotifications(data);
      });
    }

    function onDisconnect() {
      socket.off("receiveNotification");
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, [state.user,state.notifications]);

  useEffect(() => {
    localStorage.setItem("mainState", JSON.stringify(state));
  }, [state]);

  const getNotifications = async (Token) => {
    await axios
      .get(`/api/guest/notifications/${state.user._id}`, {
        headers: {
          Authorization: `Bearer ${Token.toString()}`,
        },
      })
      .then((res) => {
        dispatch({
          type: "notifications",
          payload: res.data.data,
        });
      });
  };

  useEffect(() => {
    const Token = localStorage.getItem("LIH-Token");

    if (state.user != null && Token) {
      getNotifications(Token);
    }
  }, [state.user]);

  const getWishList = async (Token) => {
    await axios
      .get(`/api/guest/users/getWishList`, {
        headers: {
          Authorization: `Bearer ${Token.toString()}`,
        },
      })
      .then((res) => {
        dispatch({
          type: "wishList",
          payload: res.data.data,
        });
      });
  };
  useEffect(() => {
    const Token = localStorage.getItem("LIH-Token");

    if (state.user != null && Token) {
      getWishList(Token);
    }
  }, [state.user]);
  const authenticate = (Token) => {
    axios
      .get("/api/guest/users/authenticate", {
        headers: {
          Authorization: `Bearer ${Token.toString()}`,
        },
      })
      .then((response) => {
        dispatch({
          type: "user",
          payload: response.data.user,
        });
      })
      .catch((error) => {
        dispatch({
          type: "user",
          payload: null,
        });
      });
  };

  useEffect(() => {
    const Token = localStorage.getItem("LIH-Token");
    if (Token) {
      authenticate(Token);
    }
  }, []);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};
